import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import logic from '../../logic'
import { Taller } from '../../types/Taller'
import CheckBoxYellow from '../checkboxYellow/CheckBoxYellow'
import TallerCard from '../tallerCard/TallerCard'
import styles from './index.module.scss'
import filterData from '../../../specific/constants/filterData'

interface FilterData {
  name: string
  key:
    | 'checkBox1'
    | 'checkBox2'
    | 'checkBox3'
    | 'checkBox4'
    | 'checkBox5'
    | 'checkBox6'
  label: string
  checked: boolean
}
const initialFilterData = filterData

type DropDownValue = '5' | '10' | '15' | '20' | 'Seleccionar'

interface Props {
  localizacion: {
    lat: string
    lon: string
  }
  filterChange: (talleres: Taller[]) => void
  submitSearch: boolean
  toggleSubmitSearch: () => void
  talleres: []
}
export default function SearchResults({
  localizacion,
  filterChange,
  submitSearch,
  toggleSubmitSearch,
  talleres,
}: Props) {
  const [filterData, setFilterData] = useState<FilterData[]>(initialFilterData)
  const [dropDownValue, setDropDownValue] = useState<DropDownValue>(
    'Seleccionar'
  )

  useEffect(() => {
    setFilterData(initialFilterData)
    setDropDownValue('Seleccionar')
  }, [submitSearch])

  if (submitSearch) {
    toggleSubmitSearch()
  }

  const handleCheckBoxChange = async event => {
    const { name } = event.target
    const newFilterData = filterData.map(item =>
      item.name === name ? { ...item, checked: !item.checked } : { ...item }
    )
    let query = makeQuery(newFilterData)
    if (dropDownValue !== 'Seleccionar') {
      query = query + `&distance=${dropDownValue}`
    }
    const res = await logic.filterTiendas(
      query,
      localizacion.lat,
      localizacion.lon,
      talleres
    )
    // Send filtered Results to parent
    setFilterData([...newFilterData])
    filterChange(res)
  }

  const makeQuery = (arr: FilterData[]) => {
    return arr
      .filter(e => e.checked)
      .map(e => encodeURIComponent(e.name) + '=1')
      .join('&')
  }

  const handleDropDownChange = async event => {
    const value: DropDownValue = event.target.value
    setDropDownValue(value)
    if (value === 'Seleccionar') {
      const newFilterData = filterData.map(item =>
        item.name === name ? { ...item, checked: !item.checked } : { ...item }
      )
      const query = makeQuery(newFilterData)
      try {
        const res = await logic.filterTiendas(
          query,
          localizacion.lat,
          localizacion.lon,
          talleres
        )
        filterChange(res)
      } catch (err) {
        console.error('no se han encontrado talleres con esta busqueda')
      }
    } else {
      let query = makeQuery(filterData)
      query = query + `&distance=${value}`
      try {
        const res = await logic.filterTiendas(
          query,
          localizacion.lat,
          localizacion.lon,
          talleres
        )
        filterChange(res)
      } catch (err) {
        console.error('no se han encontrado talleres con esta busqueda')
      }
    }
  }

  if (talleres) {
    return (
      <section className="resultados">
        <section className={styles.filter}>
          <p className={styles.title_results}>
            {' '}
            <FormattedMessage id="talleres.hemos" /> {talleres.length}{' '}
            <FormattedMessage id="talleres.founded-talleres" />{' '}
          </p>
          <div className={styles.filter_container}>
            <div className={styles.services_container}>
              <p>
                <FormattedMessage id="search-results.filter-services" />
              </p>
              <div className={styles.checkboxes_container}>
                {filterData &&
                  filterData.map((checkbox, index) => (
                    <CheckBoxYellow
                      key={index}
                      name={checkbox.name}
                      onChange={handleCheckBoxChange}
                      checked={checkbox.checked}
                      label1={checkbox.label}
                      size="small"
                    />
                  ))}
              </div>
            </div>
            <div className={styles.distance_container}>
              <p>
                <FormattedMessage id="search-results.filter-distance" />
              </p>
              <select value={dropDownValue} onChange={handleDropDownChange}>
                <FormattedMessage id="search-results.select">
                  {val => <option value="Seleccionar">{val}</option>}
                </FormattedMessage>
                <option value="5">5 km</option>
                <option value="10">10 km</option>
                <option value="15">15 km</option>
                <option value="20">20 km</option>
              </select>
            </div>
          </div>
        </section>
        <section className={styles.results__cont}>
          {talleres &&
            talleres.map((taller, index) => (
              <TallerCard key={index} position={index + 1} taller={taller} />
            ))}
        </section>
      </section>
    )
  } else return null
}
