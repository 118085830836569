// FilterButton.js
import React from 'react'
import styles from './FilterButton.module.scss'

interface DefaultActionButton {
  type: 'submit' | 'reset'
}

interface CustomActionButton {
  type?: 'button'
}

type FilterButtonProps = {
  isActive: boolean
  label: string
  total?: number
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
} & (DefaultActionButton | CustomActionButton)

const FilterButton: React.FC<FilterButtonProps> = ({
  label,
  total,
  isActive,
  onClick,
  type,
}) => {
  return (
    <button
      type={type}
      className={`${styles.filterButton} ${isActive ? styles.active : ''}`}
      onClick={onClick}>
      <span className={styles.label}>{label}</span>
      {total ? <span className={styles.total}>({total})</span> : ''}
    </button>
  )
}
export default FilterButton
