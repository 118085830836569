import { graphql } from 'gatsby'
import React, { useEffect, useReducer, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import SessionStorage from 'sessionstorage'
import { t } from '../../../common/i18n'
import { displayName, getCoordinates } from '../../../specific/build/Areas'
import TalleresSearcher from '../../../specific/components/cesta/talleres/TalleresSearcher'
import { SeoData } from '../../types/Seo'
import { MenuPrincipal } from '../../../specific/constants/menu'
import { BreadCrumbItems } from '../../components/breadCrumb/LocalizedBreadCrumb'
import Layout from '../../components/Layout'
import SearchResults from '../../components/searchResults/SearchResults'
import Directorio from '../../components/Talleres/Directorio'
import TallerMap from '../../components/tallerMap/TallerMap'
import { useSelectedMenu } from '../../../specific/helpers/customHooks/useSelectedMenu'
import logic from '../../logic'
import { Taller, TallerDistance } from '../../types/Taller'
import route from '../../utils/route'
import styles from './talleres.module.scss'
import { Area } from '../../build/Areas.js'
import { TalleresFilters } from '../../components/Talleres/FilterTalleres'
import FilterButton from '../../../shared/components/ListFilter/FilterButton'
import ButtonGrid from '../../../shared/components/ListFilter/ButtonGrid'

const initialState = {
  tiendas: null as Taller[] | null,
  postCode: '' as string,
  searchedTalleres: null as (Taller & TallerDistance)[] | null,
  searchingTalleres: false as boolean,
  haveLocalization: false as boolean,
  loading: false as boolean,
  submitSearch: false as boolean,
  error: null as string | null,
  searchedLocation: null as { lat: string; lon: string } | null,
}

type IState = typeof initialState

function reducer(state: IState, action) {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload.loading,
      }
    case 'SET_SEARCHED_VALUES':
      return {
        ...state,
        searchedTalleres: action.payload.searchedTalleres,
        searchingTalleres: action.payload.searchingTalleres,
        searchedLocation: action.payload.searchedLocation,
      }
    case 'SET_SEARCHED_TALLERES':
      return {
        ...state,
        searchedTalleres: action.payload.searchedTalleres,
      }
    case 'SET_SEARCHED_TALLERES_OK':
      return {
        ...state,
        searchedTalleres: action.payload.searchedTalleres,
        submitSearch: action.payload.submitSearch,
        error: action.payload.error,
        loading: action.payload.loading,
        searchingTalleres: action.payload.searchingTalleres,
        searchedLocation: {
          lat: action.payload.searchedLocation.lat,
          lon: action.payload.searchedLocation.lon,
        },
      }
    case 'SET_SEARCHED_TALLERES_KO':
      return {
        ...state,
        error: action.payload.error,
        loading: action.payload.loading,
        searchedTalleres: [],
      }
    case 'SET_TALLERES_LOCATION':
      return {
        ...state,
        searchedTalleres: action.payload.searchedTalleres,
        searchingTalleres: action.payload.searchingTalleres,
        loading: action.payload.loading,
        postCode: action.payload.postCode,
        searchedLocation: {
          lat: action.payload.searchedLocation.lat,
          lon: action.payload.searchedLocation.lon,
        },
      }
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload.error,
        loading: action.payload.loading,
      }
    case 'SET_POST_CODE':
      return {
        ...state,
        postCode: action.payload.postCode,
      }
    case 'SET_SUBMIT_SEARCH':
      return {
        ...state,
        submitSearch: action.payload.submitSearch,
      }
  }
}

interface Props {
  data: PageData
  pageContext: {
    area: Area
    glob: string
    isCreatedByStatefulCreatePages: boolean
    url: string
  }
}
const PageTemplate = ({ data, pageContext }: Props) => {
  useSelectedMenu(MenuPrincipal.TALLERES)
  const talleres = data.allTalleres.edges.map((item) => item.node)
  const area = pageContext.area
  const [localState, localDispatch] = useReducer(reducer, {
    ...initialState,
    haveLocalization: !!area.area,
    searchedLocation: area.area ? getCoordinates(area.area) : null,
  })

  useEffect(() => {
    const hasSearch = SessionStorage.getItem('hasSearch')
    if (hasSearch === 'true') {
      SessionStorage.setItem('hasSearch', false)
      const postCode = SessionStorage.getItem('searchValue')
      if (postCode !== 'null') {
        logic
          .searchByPostCode(postCode, false)
          .then((res) => {
            localDispatch({
              type: 'SET_SEARCHED_VALUES',
              payload: {
                searchedTalleres: res.tiendas,
                searchingTalleres: true,
                searchedLocation: { lat: res.latitud, lon: res.longitud },
              },
            })
          })
          .catch(() => {
            localDispatch({
              type: 'SET_SEARCHED_TALLERES_KO',
              payload: {
                error: t('talleres.error_buscar_talleres', { postCode }),
                loading: localState.loading,
              },
            })
          })
      }
    }

    if (window) window.scrollTo(0, 0)
  }, [])

  const handleFilterChange = (arr) => {
    localDispatch({
      type: 'SET_SEARCHED_TALLERES',
      payload: {
        searchedTalleres: arr,
      },
    })
  }

  const geolocateMe = () => {
    if (!localState.loading) {
      localDispatch({
        type: 'SET_LOADING',
        payload: {
          loading: true,
        },
      })
      navigator.geolocation.getCurrentPosition(
        async (pos) => {
          const { latitude, longitude } = pos.coords
          logic.retrieveTiendas(latitude, longitude).then((res) => {
            localDispatch({
              type: 'SET_TALLERES_LOCATION',
              payload: {
                searchedTalleres: res,
                searchingTalleres: true,
                loading: false,
                postCode: '',
                searchedLocation: {
                  lat: `${latitude}`,
                  lon: `${longitude}`,
                },
              },
            })
          })
        },
        (err) => {
          localDispatch({
            type: 'SET_ERROR',
            payload: {
              error: t('talleres.error_geolocal_talleres'),
              loading: false,
            },
          })
          console.error(err)
        },
        { timeout: 7000 }
      )
    }
  }

  const handlePostCodeChange = (postCode) => {
    localDispatch({
      type: 'SET_POST_CODE',
      payload: {
        postCode,
      },
    })
  }

  const handleSubmit = async (postCode) => {
    SessionStorage.setItem('searchValue', postCode)
    if (!postCode.trim().length) {
      localDispatch({
        type: 'SET_ERROR',
        payload: {
          error: t('talleres.error_buscar_talleres_vacio'),
          loading: false,
        },
      })
    } else {
      try {
        localDispatch({
          type: 'SET_LOADING',
          payload: {
            loading: true,
          },
        })
        logic
          .searchByPostCode(postCode, false)
          .then((searchedTalleres) => {
            localDispatch({
              type: 'SET_SEARCHED_TALLERES_OK',
              payload: {
                searchedTalleres: searchedTalleres.tiendas,
                submitSearch: true,
                error: null,
                loading: false,
                searchingTalleres: true,
                searchedLocation: {
                  lat: searchedTalleres.latitud,
                  lon: searchedTalleres.longitud,
                },
              },
            })
          })
          .catch((err) => {
            localDispatch({
              type: 'SET_SEARCHED_TALLERES_KO',
              payload: {
                error: t('talleres.error_buscar_talleres', { postCode }),
                loading: localState.loading,
              },
            })
            console.error(err)
          })
      } catch (err) {
        localDispatch({
          type: 'SET_SEARCHED_TALLERES_KO',
          payload: {
            error: t('talleres.error_buscar_talleres', { postCode }),
            loading: localState.loading,
          },
        })
        console.error(err)
      }
    }
  }

  const toggleSubmitSearch = () => {
    localDispatch({
      type: 'SET_SUBMIT_SEARCH',
      payload: {
        submitSearch: false,
      },
    })
  }

  const breadCrumItems = [
    { display: t('bread_crumb.inicio'), link: route('index') },
  ] as BreadCrumbItems

  if (area.area !== '') {
    breadCrumItems.push({
      display: t('bread_crumb.talleres-mecanicos'),
      link: route('talleres.area', {
        area: '',
      }),
    })

    breadCrumItems.push({
      display: t(`talleres.${area.slug}`),
    })
  } else {
    breadCrumItems.push({
      display: t('bread_crumb.talleres-mecanicos'),
    })
  }

  // There is an exception with Barcelona Ciudad
  // as talleres shown belong to Barcelona province but
  // we only want to map those in the city
  const areaException = area.area === 'BARCELONA CIUDAD'

  const [activeFilter, setActiveFilter] = useState<string>('todos')
  const [filteredTalleres, setFilteredTalleres] = useState<Taller[]>(talleres)

  const handleFilterClick = (filter: string) => {
    setActiveFilter(filter === activeFilter ? 'todos' : filter)
  }

  const buildAperturaFilter = (filterKey: string) => {
    switch (filterKey) {
      case 'todos':
        return talleres
      case 'sabados':
        return talleres.filter((taller) => !!taller.horario_sab_m_front)
      case 'mediodia':
        return talleres.filter(
          (taller) =>
            !taller.horario_lunvie_t_front && !!taller.horario_lunvie_m_front
        )
      default:
        return talleres.filter((taller) => taller[filterKey])
    }
  }

  useEffect(() => {
    setFilteredTalleres(buildAperturaFilter(activeFilter))
  }, [activeFilter])

  return (
    <Layout
      selector={false}
      seoData={data.seoData}
      breadCrumItems={breadCrumItems}>
      <section className={styles.talleres}>
        {!area.area && (
          <React.Fragment>
            <section className={styles.talleres__buscador}>
              <h1>
                <FormattedMessage id="talleres.subtitle" />
              </h1>
              <div className={styles.searcher_container}>
                <TalleresSearcher
                  showPostCodeLabel={false}
                  geolocateMe={geolocateMe}
                  loading={localState.loading}
                  postCode={localState.postCode}
                  setState={handlePostCodeChange}
                  retrieveTalleres={handleSubmit}
                  parentStyles={{
                    formStyles: styles.search_form,
                    buttonStyles: styles.search_form_btn,
                    inputStlyes: styles.search_form_input,
                    geoStyles: styles.search_form_geo,
                  }}
                  btnText={t('talleres.search-talleres')}
                />
              </div>
            </section>
            <section className={styles.resultados__busqueda}>
              <SearchResults
                toggleSubmitSearch={toggleSubmitSearch}
                submitSearch={localState.submitSearch}
                filterChange={handleFilterChange}
                localizacion={localState.searchedLocation}
                talleres={localState.searchedTalleres}
              />
            </section>
          </React.Fragment>
        )}

        {!!area.area && (
          <React.Fragment>
            <h1 className={styles.title_list}>
              {t('talleres.title-list-talleres', {
                area: t(displayName(area)),
              })}
            </h1>
            <ButtonGrid>
              {TalleresFilters.map((filter) => (
                <FilterButton
                  key={filter.key}
                  label={filter.label}
                  total={buildAperturaFilter(filter.key).length}
                  isActive={activeFilter === filter.key}
                  onClick={() => handleFilterClick(filter.key)}
                />
              ))}
            </ButtonGrid>
            <Directorio talleres={filteredTalleres} areaToShow={area.area} />
          </React.Fragment>
        )}
        {!!area.area && (
          <h2 className={styles.title_map}>
            {t('talleres.title-list-area', {
              area: t(displayName(area)),
            })}
          </h2>
        )}
        <div className={styles.map}>
          {localState.searchedTalleres && localState.searchedTalleres.length ? (
            <TallerMap
              formattedTalleres={talleres}
              haveLocalization={localState.haveLocalization}
              searchingTalleres={localState.searchingTalleres}
              searchedTalleres={localState.searchedTalleres}
            />
          ) : (
            <TallerMap
              formattedTalleres={
                areaException
                  ? talleres.filter(
                      (taller) => taller.poblacion === 'BARCELONA'
                    )
                  : talleres
              }
              haveLocalization={localState.haveLocalization}
              searchedLocation={localState.searchedLocation}
              zoomIn={areaException}
            />
          )}
        </div>

        {!area.area && (
          <React.Fragment>
            <h2 className={styles.title_list_multiple}>
              <FormattedMessage id="talleres.title-list" />
            </h2>
            <ButtonGrid>
              {TalleresFilters.map((filter) => (
                <FilterButton
                  key={filter.key}
                  label={filter.label}
                  total={buildAperturaFilter(filter.key).length}
                  isActive={activeFilter === filter.key}
                  onClick={() => handleFilterClick(filter.key)}
                />
              ))}
            </ButtonGrid>
            <Directorio talleres={filteredTalleres} areaToShow={area.area} />
          </React.Fragment>
        )}
      </section>
    </Layout>
  )
}

export default PageTemplate

interface PageData {
  allTalleres: {
    edges: {
      node: Taller
    }[]
  }
  seoData: SeoData
}

export const pageQuery = graphql`
  query talleresProvinciaQuery($url: String!, $glob: String!) {
    allTalleres(filter: { provincia: { glob: $glob } }) {
      edges {
        node {
          poblacion
          provincia
          codigo_taller
          activo
          slug
          alias
          empresa
          direccion
          cpostal
          email
          lat
          lon
          telefono
          horario_lunvie_m_front
          horario_lunvie_t_front
          horario_sab_m_front
          info_directorio_es
          info_directorio_ca
          turismo
          moto
          camion
          renting
          agricola
        }
      }
    }
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
