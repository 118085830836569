import { Link, navigate } from 'gatsby'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import Location from '../../assets/images/Talleres/icon_localizacion3.svg'
import Phone from '../../assets/images/Talleres/icon_telefono.svg'
import Adress from '../../assets/images/Talleres/icon_ubicacion4.svg'
import {
  CitaPreviaEnum,
  citaPreviaSteps,
} from '../../components/CitaPrevia/CitaPreviaSteps'
import {
  setCitaPreviaStep,
  setSelectedTaller,
} from '../../context/actions/citaPreviaActions'
import AppContext from '../../context/context'
import { Taller, TallerDistance } from '../../types/Taller'
import route from '../../utils/route'
import { slugify } from '../../utils/slugify'
import Button from '../buttons/Button'
import styles from './index.module.scss'

interface Props {
  taller: Taller & TallerDistance
  position: number
}

const TallerCard = ({ taller, position }: Props) => {
  const { dispatch } = useContext(AppContext)
  const goToCitaPrevia = () => {
    dispatch(setSelectedTaller(taller, window.location.pathname))
    dispatch(
      setCitaPreviaStep(citaPreviaSteps[CitaPreviaEnum.SELECCION_SERVICIO].step)
    )
    navigate(citaPreviaSteps[CitaPreviaEnum.SELECCION_SERVICIO].route)
  }

  return (
    <div className={styles.taller_card}>
      <p className={styles.distance_card}>{taller.distance.toFixed(1)} km</p>
      <div className={styles.text_card}>
        <div className={styles.position_text}>
          <div className={styles.icon}>
            <Location />
            <div className={styles.icon_text}>{position}</div>
          </div>
          <p className={styles.alias}>
            {taller.alias.charAt(0).toUpperCase() +
              taller.alias.slice(1).toLowerCase()}
          </p>
        </div>
        <p className={styles.adress}>
          <Adress />
          <span className={styles.icon_text}>{`${taller.direccion}`}</span>
        </p>
        <p
          className={
            styles.postalcode
          }>{`${taller.cpostal} ${taller.poblacion}`}</p>
        <a className={styles.phone} href={`tel:${taller.telefono}`}>
          <Phone />
          {taller.telefono}
        </a>
      </div>
      <div className={styles.container_buttons}>
        <Button onClick={goToCitaPrevia} buttonClass="rodi_full_width">
          <FormattedMessage id="taller-card.appointment" />
        </Button>
        <Link
          className={styles.link__button}
          to={route('talleres.taller', {
            provincia: slugify(taller.provincia),
            taller: slugify(taller.slug),
          })}>
          <FormattedMessage id="taller-card.view" />
        </Link>
      </div>
    </div>
  )
}

export default TallerCard
