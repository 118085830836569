import React from 'react'
import styles from './TalleresGeo.module.scss'
import SpinnerWithText from '../../spinnerWithText/SpinnerWithText'
import Geo from '../../../assets/images/Talleres/icon_ubicacion.svg'
import { FormattedMessage } from 'react-intl'

export default function TalleresGeo({ setState, loading, geolocateMe }) {
  return (
    <>
      {loading ? (
        <SpinnerWithText
          className={styles.spinner_container}
          size={30}
          text=""
        />
      ) : (
        <div
          className={styles.geolocation__cont}
          onClick={() => {
            setState('')
            geolocateMe()
          }}>
          <Geo />
          <p>
            <FormattedMessage id="talleres.use-geolocation" />
          </p>
        </div>
      )}
    </>
  )
}
