import React from 'react'
import styles from './TalleresSearcher.module.scss'
import TalleresGeo from '../../../../../src/common/components/cesta/talleres/TalleresGeo'

import locations from '../../../constants/regions'

interface Props {
  postCode: string
  setState: (postCode: string) => void
  retrieveTalleres: (postcode: string) => void
  loading: boolean
  geolocateMe: () => void
  showPostCodeLabel: boolean
  parentStyles?: {
    formStyles: any
    inputStlyes: any
    buttonStyles: any
    geoStyles: any
  }
  btnText?: string
}

export default function TalleresSearcher({
  postCode,
  setState,
  retrieveTalleres,
  loading,
  geolocateMe,
}: Props) {
  const handleChange = (e: any) => {
    setState(e.target.value)
    e.target.value && retrieveTalleres(e.target.value)
  }

  return (
    <div className={styles.searcher_container}>
      <select
        className={styles.locations_options}
        value={postCode}
        onChange={handleChange}>
        <option value="">Escolha localização</option>
        {locations.map((location, i) => (
          <option key={i} value={location.postCode}>
            {location.name}
          </option>
        ))}
      </select>
      <TalleresGeo
        setState={setState}
        loading={loading}
        geolocateMe={geolocateMe}
      />
    </div>
  )
}
