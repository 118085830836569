import { graphql, StaticQuery } from 'gatsby'
import GoogleMapReact from 'google-map-react'
import React, { useEffect, useState } from 'react'
import { Taller, TallerDistance, TallerLocation } from '../../types/Taller'
import TallerPin from '../tallerPin/TallerPin'
const defaultLatitude: number = parseFloat(process.env.GATSBY_DEFAULT_LATITUDE)
const defaultLongitude: number = parseFloat(
  process.env.GATSBY_DEFAULT_LONGITUDE
)

interface Props {
  searchedTalleres: (Taller & TallerLocation)[]
  searchingTalleres: boolean
  fichaTaller: Taller & TallerDistance & TallerLocation
  cestaTaller?: boolean
  haveLocalization: boolean
  data: any
  zoomIn?: boolean
  formattedTalleres: (Taller & TallerLocation)[]
  searchedLocation: {
    lat: string
    lon: string
  }
}

const TallerMap = ({
  searchedTalleres,
  searchingTalleres,
  fichaTaller,
  cestaTaller,
  haveLocalization,
  data,
  formattedTalleres,
  searchedLocation,
  zoomIn,
}: Props) => {
  const tiendas = data.allTalleres.edges
  const [reLat, setreLat] = useState(defaultLatitude)
  const [relon, setrelon] = useState(defaultLongitude)
  const [pinOpen, setpinOpen] = useState(null)
  const [mapLoading, setMapLoading] = useState(true)

  const fixcenter = (pinlat, pinlon, clicked) => {
    setpinOpen(clicked)
    setreLat(pinlat)
    setrelon(pinlon)
  }

  const exampleMapStyles = [
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
  ]

  useEffect(() => {
    setMapLoading(false)
  }, [])

  if (fichaTaller) {
    const { lat, lon } = fichaTaller
    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.GATSBY_MAPS_KEY}` }}
        center={{
          lat: parseFloat(lat),
          lng: parseFloat(lon),
        }}
        options={{
          styles: exampleMapStyles,
        }}
        zoom={15}>
        <TallerPin
          lat={parseFloat(lat)}
          lng={parseFloat(lon)}
          fichaTaller={fichaTaller}
        />
      </GoogleMapReact>
    )
  }
  if (searchingTalleres) {
    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.GATSBY_MAPS_KEY}` }}
        center={{
          lat: parseFloat(searchedTalleres[0].lat),
          lng: parseFloat(searchedTalleres[0].lon),
        }}
        options={{
          styles: exampleMapStyles,
        }}
        zoom={13}>
        {searchedTalleres &&
          cestaTaller &&
          searchedTalleres.map((item, index) => {
            return (
              <TallerPin
                key={index}
                lat={parseFloat(item.lat)}
                lng={parseFloat(item.lon)}
                tiendas={searchedTalleres}
                clicked={item.codigo_taller}
                position={index + 1}
                cestaTaller
              />
            )
          })}

        {searchedTalleres &&
          !cestaTaller &&
          searchedTalleres.map((item, index) => {
            return (
              <TallerPin
                key={index}
                lat={parseFloat(item.lat)}
                lng={parseFloat(item.lon)}
                tiendas={searchedTalleres}
                clicked={item.codigo_taller}
                position={index + 1}
                cestaTaller
              />
            )
          })}
      </GoogleMapReact>
    )
  }

  if (haveLocalization) {
    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.GATSBY_MAPS_KEY}` }}
        center={{
          lat: parseFloat(searchedLocation.lat),
          lng: parseFloat(searchedLocation.lon),
        }}
        zoom={zoomIn ? 12 : 9}>
        {formattedTalleres &&
          formattedTalleres.map((item, index) => {
            return (
              <TallerPin
                key={index}
                onFix={fixcenter}
                lat={parseFloat(item.lat)}
                lng={parseFloat(item.lon)}
                tiendas={formattedTalleres}
                clicked={item.codigo_taller}
                open={pinOpen}
              />
            )
          })}
      </GoogleMapReact>
    )
  } else if (!haveLocalization && !mapLoading) {
    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.GATSBY_MAPS_KEY}` }}
        center={{
          lat: reLat,
          lng: relon,
        }}
        zoom={7}>
        {tiendas &&
          tiendas.map((item, index) => {
            return (
              <TallerPin
                key={index}
                onFix={fixcenter}
                lat={parseFloat(item.node.lat)}
                lng={parseFloat(item.node.lon)}
                tiendas={formattedTalleres}
                clicked={item.node.codigo_taller}
                open={pinOpen}
              />
            )
          })}
      </GoogleMapReact>
    )
  }

  if (!searchingTalleres) {
    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.GATSBY_MAPS_KEY}` }}
        center={{
          lat: reLat,
          lng: relon,
        }}
        zoom={7}></GoogleMapReact>
    )
  } else return null
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allTalleres {
          edges {
            node {
              lat
              lon
              codigo_taller
              alias
              empresa
              direccion
              cpostal
              provincia
            }
          }
        }
      }
    `}
    render={data => <TallerMap data={data} {...props} />}
  />
)
