import React from 'react'
import styles from './buttonGrid.module.scss'

type ButtonGroupProps = {
  children: string | (string | JSX.Element)[] | JSX.Element
}

export default function ButtonGrid({ children }: ButtonGroupProps) {
  return <section className={styles.filterGrid}>{children}</section>
}
